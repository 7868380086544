<template>
  <div class="historial-citas mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Historial de citas
          </v-card-title>
          <v-card-subtitle>
            Desde este apartado podrás visualizar el historial de las citas de
            tu sede en el rango de fechas que tu determines
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_1"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha inicial"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_2"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_fin"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-tabs v-model="tab" :color="color">
                  <v-tooltip top max-width="200">
                    <template v-slot:activator="{ on, attrs }">
                      <v-tab href="#tab-1" v-on="on" v-bind="attrs">
                        Citas pendientes
                      </v-tab>
                    </template>
                    <span
                      >La citas confirmadas, y / o procesadas tambien se
                      listaran en este apartado</span
                    >
                  </v-tooltip>
                  <v-tab href="#tab-2">
                    Citas pagadas
                  </v-tab>
                  <v-tab href="#tab-3">
                    Citas canceladas
                  </v-tab>
                  <v-tabs-slider></v-tabs-slider>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-1">
                    <citas-pendientes :inicio="date" :fin="date_fin" />
                  </v-tab-item>
                  <v-tab-item value="tab-2">
                    <citas-pagadas :inicio="date" :fin="date_fin" />
                  </v-tab-item>
                  <v-tab-item value="tab-3">
                    <citas-canceladas :inicio="date" :fin="date_fin" />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CitasPendientes from "../../components/CitasPendientes.vue";
import CitasPagadas from "../../components/CitasPagadas.vue";
import CitasCanceladas from "../../components/CitasCanceladas.vue";

import * as moment from "moment";
moment.locale("es");

export default {
  components: { CitasPendientes, CitasPagadas, CitasCanceladas },
  name: "historialCitas",
  data: () => ({
    date: moment().format("YYYY-MM-DD"),
    date_fin: moment().format("YYYY-MM-DD"),
    menu_1: false,
    menu_2: false,
    tab: "tab-1",
    color: "primary",
  }),
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes", "recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  watch: {
    tab(val) {
      if (val == "tab-1") this.color = "primary";
      if (val == "tab-2") this.color = "success";
      if (val == "tab-3") this.color = "error";
      return this.tab;
    },
  },
};
</script>
