<template>
  <div class="citas-canceladas">
    <v-data-table
      :headers="headers"
      :items="lista"
      :search="search"
      :footer-props="{ itemsPerPageText: 'Citas' }"
      :loading="loading_t"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="4" class="px-0">
            <v-text-field
              outlined
              rounded
              dense
              append-icon="mdi-magnify"
              label="Buscar"
              class="mt-6"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "citasCanceladas",
  props: ["inicio", "fin"],
  data: () => ({
    headers: [
      {
        align: "start",
        text: "Fecha",
        value: "fecha",
        width: 100,
      },
      {
        text: "Hora",
        value: "hora",
        filterable: false,
        sortable: false,
        width: 50,
      },
      {
        text: "Cliente",
        value: "cliente",
      },
      {
        text: "Profesional",
        value: "profesional",
        sortable: false,
      },
      {
        text: "Servicio",
        value: "servicio",
        sortable: false,
      },
      {
        text: "Cancelación",
        value: "cancelacion",
        width: 100,
        sortable: false,
      },
      {
        text: "Razón",
        value: "razon",
        width: 150,
        sortable: false,
      },
      {
        text: "Quien cancela",
        value: "cancelo",
        sortable: false,
      },
      {
        text: "Notas",
        value: "notas",
        sortable: false,
        width: 150,
        filterable: false,
      },
    ],
    search: "",
    citas: [],
    loading_t: false
  }),
  methods: {
    loadHistorial() {
      this.loading_t = true
      const body = {
        route: "/citas_canceladas",
        params: {
          date: {
            inicio: this.inicio,
            fin: this.fin,
          },
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.citas = response.data.data;
      }).finally(() => (this.loading_t = false))
    },
  },
  created() {
    this.loadHistorial();
  },
  computed: {
    lista() {
      return this.citas.map((cita) => {
        cita.fecha = moment(cita.fecha).format("ll");
        cita.hora = moment(cita.hora, "HH:mm:ss").format("h:mm A");
        cita.cancelacion = moment(cita.cancelacion).format("ll");
        return cita;
      });
    },
  },
  watch: {
    inicio(val) {
      this.loadHistorial();
      return this.inicio;
    },
    fin(val) {
      this.loadHistorial();
      return this.fin;
    },
  },
};
</script>
