<template>
  <div class="citas-pagadas">
    <v-data-table
      :headers="headers"
      :items="lista"
      :search="search"
      :footer-props="{ itemsPerPageText: 'Citas' }"
      dense
      :loading="loading_t"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="4" class="px-0">
            <v-text-field
              outlined
              rounded
              dense
              append-icon="mdi-magnify"
              label="Buscar"
              class="mt-6"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:item.total="{ item }">
        <router-link
          :to="'/detallefactura/' + item.factura_id"
          v-if="item.factura_id != undefined"
        >
          {{ item.total | currency }}
        </router-link>
        <span v-else>
          {{ item.total | currency }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "citasPagadas",
  props: ["inicio", "fin"],
  data: () => ({
    headers: [
      {
        align: "start",
        text: "Fecha",
        value: "fecha",
        width: 155,
      },
      {
        text: "Hora",
        value: "hora",
        filterable: false,
        width: 95,
      },
      {
        text: "Cliente",
        value: "cliente",
      },
      {
        text: "Profesional",
        value: "profesional",
        sortable: false,
      },
      {
        text: "Servicio",
        value: "servicio",
        sortable: false,
      },
      {
        text: "Precio",
        value: "total",
        filterable: false,
      },
      {
        text: "Agendó",
        value: "agendo",
        sortable: false,
      },
      {
        text: "Notas",
        value: "notas",
        sortable: false,
        width: 250,
        filterable: false,
      },
    ],
    search: "",
    citas: [],
    loading_t: false,
  }),
  methods: {
    loadHistorial() {
      this.loading_t = true;
      const body = {
        route: "/citas_pagadas",
        params: {
          date: {
            inicio: this.inicio,
            fin: this.fin,
          },
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) this.citas = response.data.data;
        })
        .finally(() => (this.loading_t = false));
    },
  },
  created() {
    this.loadHistorial();
  },
  computed: {
    lista() {
      return this.citas.map((cita) => {
        cita.fecha = moment(cita.fecha).format("ll");
        cita.hora = moment(cita.hora, "HH:mm:ss").format("h:mm A");
        cita.total = parseFloat(cita.total);
        cita.factura_id =
          cita.factura_id == "undefined" ? undefined : cita.factura_id;
        return cita;
      });
    },
  },
  watch: {
    inicio(val) {
      this.loadHistorial();
      return this.inicio;
    },
    fin(val) {
      this.loadHistorial();
      return this.fin;
    },
  },
};
</script>
